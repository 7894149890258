import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const RouteInterceptor = ({ routeChecks, children }) => {
    const navigate = useNavigate();
    const location = useLocation(); // Use react-router's useLocation hook for reactive route tracking
    const pathname = location.pathname; // Current route path

    useEffect(() => {
        const checkResult = routeChecks(pathname);        
        
        if (checkResult?.redirectTo && checkResult.redirectTo !== pathname) {
            // Redirect only if the target route is different from the current route
            navigate(checkResult.redirectTo, { replace: true });
        }
    }, [pathname, navigate, routeChecks]); // Dependencies ensure useEffect triggers only when route or checks change

    return children;
};

export default RouteInterceptor;
