// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import useJwt from '../Auth/jwt/useJwt';

export const getLocations = createAsyncThunk('locations/getLocations', async () => {
    try {
        const response = await useJwt.getLocations();
        return {
            data: response?.data?.data || []
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const getDashboardLocationFilters = createAsyncThunk('locations/dashboardLocationFilters', async () => {
    try {
        const response = await useJwt.getDashboardLocationFilters();        
        return {
            data: response?.data?.data || []
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const getGlobeData = createAsyncThunk('locations/getGlobeData', async () => {
    try {
        const response = await useJwt.globeData()
        return {
            data: response?.data?.data || []
        }
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
})

export const getMajors = createAsyncThunk('locations/getMajors', async () => {
    try {
        const response = await useJwt.getMajors();
        return {
            data: response?.data?.data?.map(major => ({ id: major.id, value: major.name, label: major.name })) || [],
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong',
        };
    }
});

export const locationsSlice = createSlice({
    name: 'locations',
    initialState: {
        loading: false,
        locations: [],
        dashboardLocationFilters: {
            data: [],
            loading: false
        },
        globeData: {
            data: [],
            loading: false
        },
        majors: {
            data: [],
            loading: false,
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLocations.pending, (state) => {
                state.locations = [];
                state.loading = true;
            })
            .addCase(getLocations.fulfilled, (state, action) => {
                state.loading = false;
                state.locations = action.payload.data;
            })
            .addCase(getDashboardLocationFilters.pending, (state) => {                
                state.dashboardLocationFilters.data = [];
                state.dashboardLocationFilters.loading = true;
            })
            .addCase(getDashboardLocationFilters.fulfilled, (state, action) => {
                state.dashboardLocationFilters.loading = false;
                state.dashboardLocationFilters.data = action.payload.data;
            })
            .addCase(getGlobeData.pending, (state) => {
                state.globeData.data = [];
                state.globeData.loading = true;
            })
            .addCase(getGlobeData.fulfilled, (state, action) => {
                state.globeData.loading = false;
                state.globeData.data = action.payload.data;
            })
            .addCase(getMajors.pending, (state) => {
                state.majors.data = [];
                state.majors.loading = true;
            })
            .addCase(getMajors.fulfilled, (state, action) => {
                state.majors.loading = false;
                state.majors.data = action.payload.data;
            });
    }
});

export default locationsSlice.reducer;
