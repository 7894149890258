// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import oNetConfig from '../Auth/oNetConfig'; 
import { fetchData } from '../utility/utils';

export const getIndustry = createAsyncThunk('industiesList/getIndustry', async () => {
    try {
        const response = await fetchData(`${oNetConfig.browseCareerByIndustry}`); 
        return {
            data: response.data?.industry || []
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const industriesList = createSlice({
    name: 'industries',
    initialState: {
        loading: false,
        industriesList: [],
        error: ''
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getIndustry.pending, (state) => {
                state.loading = true;
                state.industriesList = [];
                state.error = '';
            })
            .addCase(getIndustry.fulfilled, (state, action) => {
                state.loading = false;
                state.industriesList = [
                    ...action.payload.data,
                    { code: 'not_sure', title: 'Not Sure' }
                ];
                state.error = '';
            })
            .addCase(getIndustry.rejected, (state, action) => {
                state.loading = false;
                state.industriesList = [];
                state.error = action.payload.error;
            });
    }
});

export default industriesList.reducer;
