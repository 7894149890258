// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../Auth/oNetConfig';
import { fetchData, groupCareersByAlphabet } from '../utility/utils';

export const getClusters = createAsyncThunk('careers/getClusters', async () => {
    try {
        const res = await apiInstance.get('/ws/online/career_clusters');
        return {
            data: res?.data?.career_cluster || []
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const getAllCareers = createAsyncThunk('careers/getAllCareers', async () => {
    try {
        const firstRes = await apiInstance.get('/ws/mnm/careers');
        const data = firstRes?.data;
        const total = data?.total;
        if (total > 20 && data?.career) {
            const secondRes = await apiInstance.get(`/ws/mnm/careers?start=21&end=${total}`);
            const secondData = secondRes?.data;
            if (secondData?.career) {
                const payload = [...data.career, ...secondData.career];
                const groupedPaylaod = groupCareersByAlphabet(payload);
                return {
                    data: groupedPaylaod,
                    careers: payload,
                    total: payload?.length || 0
                };
            }
        } else {
            const groupedPaylaod = groupCareersByAlphabet(firstRes?.data?.career || []);
            return {
                data: groupedPaylaod,
                careers: firstRes?.data?.career || [],
                total: firstRes?.data?.career?.length || 0
            };
        }
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const getBrightCareers = createAsyncThunk(
    'careers/getBrightCareers',
    async (key, { dispatch, rejectWithValue }) => {
        try {
            dispatch(handleBrightCareersLoading({ key, loading: true }));
            const firstRes = await fetchData(`/ws/mnm/bright_outlook/${key}?start=1&end=20`);
            const firstData = firstRes?.data;
            const total = firstData?.total || 0;

            // Dispatch the first batch of careers to the state
            dispatch(updateCareers({ key, data: firstRes?.data?.career || [] }));

            if (total > 20) {
                const secondRes = await fetchData(`/ws/mnm/bright_outlook/${key}?start=21&end=${total}`);
                const secondData = secondRes?.data;
                if (secondData?.career) {
                    const payload = [...firstData.career, ...secondData.career];
                    dispatch(updateCareers({ key, data: payload }));
                }
            }
            dispatch(handleBrightCareersLoading({ key, loading: false }));
        } catch (error) {
            dispatch(handleBrightCareersLoading({ key, loading: false }));
            return rejectWithValue(error.message);
        }
    }
);

export const careers = createSlice({
    name: 'careers',
    initialState: {
        loading: false,
        careers: [],
        groupedCareers: [],
        totalCareersCount: 0,
        clusters: {
            data: [],
            loading: false
        },
        brightCareers: {
            grow: {
                data: [],
                loading: false
            },
            openings: {
                data: [],
                loading: false
            },
            emerging: {
                data: [],
                loading: false
            }
        },
        error: '',
        brightError: ''
    },
    reducers: {
        updateCareers: (state, action) => {
            const prevData = state.brightCareers?.[action.payload.key]?.data || [];
            state.brightCareers[action.payload.key].data = [...prevData, ...action.payload.data];
        },
        handleBrightCareersLoading: (state, action) => {
            state.brightCareers[action.payload.key].loading = action.payload.loading;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCareers.pending, (state) => {
                state.loading = true;
                state.groupedCareers = [];
                state.error = '';
                state.totalCareersCount = 0;
            })
            .addCase(getAllCareers.fulfilled, (state, action) => {
                state.loading = false;
                state.careers = action.payload.careers;
                state.groupedCareers = action.payload.data;
                state.totalCareersCount = action.payload.total;
                state.error = '';
            })
            .addCase(getAllCareers.rejected, (state, action) => {
                state.loading = false;
                state.groupedCareers = [];
                state.totalCareersCount = 0;
                state.error = action.payload.error;
            })
            .addCase(getClusters.pending, (state) => {
                state.clusters.loading = true;
                state.clusters.data = [];
            })
            .addCase(getClusters.fulfilled, (state, action) => {
                state.clusters.loading = false;
                state.clusters.data = action.payload.data;
            })
            .addCase(getClusters.rejected, (state) => {
                state.clusters.loading = false;
                state.clusters.data = [];
            })
            .addCase(getBrightCareers.rejected, (state, action) => {
                state.brightError = action.payload;
            });
    }
});

export const { updateCareers, handleBrightCareersLoading } = careers.actions;

export default careers.reducer;
