import useJwt from "../Auth/jwt/useJwt";
const userData = useJwt.getUserData();

export const privateRouteChecks = () => {
  if (
    userData?.length > 0 &&
    !userData.is_admin &&
    !userData.is_profile_completed
  ) {
    return { redirectTo: "/setup-profile" };
  }

  return null;
};
